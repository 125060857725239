.redes {
  width: 80px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.redes a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  transition: all 0.2s ease-in-out;
}

.redes a svg {
  width: 30px;
  fill: #ffffff;
}

.redes .discord {
  background-color: rgb(0, 60, 255);
  border-radius: 0 0 10px 0;
}

.redes .instagram {
  background: linear-gradient(45deg, #833ab4, #fd1d1d, #f56040);
  border-radius: 0 10px 0 0;
}

.redes .github {
  background-color: rgb(24, 22, 22);
}

.redes .twitter {
  background-color: #000000;
}

.redes .facebook {
  background-color: #006aff;
}

.redes a:hover {
  width: 130%;
  border-radius: 0 10px 10px 0;
}
