.primary-container{
  padding-top: 80px;
  padding-bottom: 80px;
  /* background-color: #0069B4; */
  /* background: linear-gradient(135deg, #0069B4 0%, #0069B4 50%, #f2faef 100%); */
  /* background-color: #db5b20; */
  background-color: #2c5c88;
  color: #f4f4f4;
  /* text-shadow: 2px 2px 2px rgba(251, 251, 251, 0.6); */
}
.primary-splitter{
  display: flex;
  flex-wrap: wrap;
  /* padding-left: 10px;
  padding-right: 10px; */
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.primary-half-section{
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.side-by-side-img{
  width: 100%;
  border-radius: 5%;
}

@media (max-width:985px){
  .primary-container h2{
    font-size: medium;
  }
}
@media (max-width:800px){
  .primary-half-section{
    width: 90%;
  }
  
}
@media (max-width:600px){
  .primary-container{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
}
@media (max-width:800px) {
  .primary-splitter{
    flex-direction:column
  }
}

@media (max-width:700px) {
  .primary-splitter{
    flex-direction:column
  }
}