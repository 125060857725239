.services-page__container{
  padding-top: 80px;
}
.services-page__hero{
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 2px 12px 8px rgba(0, 0, 0, 0.6);
}
.reduced-operation{
  background-image: url("../../images/backgrounds/OperationalCostsBAnner.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 80px;
  height: 35vh;
  background-color: rgba(0, 0, 0, 0.7); /* adjust the alpha for darker/lighter */
}

.content {
  position: relative;
  color: white; /* makes the text brighter */
  padding: 20px; /* adjust padding as needed */
  z-index: 1; /* ensures content is above the overlay */
}
.services-page__text-section{
  padding: 5%
}

@media (max-width:800px){
  .services-page__hero{
    min-height: 75vh;
    text-align: center;
  }
  .overlay{
    height: 75vh;
  }
  h3{
    font-size: medium;
  }
}