body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.button {
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  /* Add other styling as needed */
}
/* styles.css */

:root {
  /* Backgrounds */
  --background-main: #5193d5;
  --background-alternate: #ffffff;

  /* Text colors */
  --text-main: #212529;
  --text-highlight: #0d6efd;

  /* Button colors */
  --button-primary-bg: #0d6efd;
  --button-primary-text: #ffffff;
  --button-secondary-bg: #6c757d;
  --button-secondary-text: #ffffff;
  --button-warning-bg: #dc3545;
  --button-warning-text: #ffffff;
}

/* Optional: Define a dark theme or any other theme as a class */
.dark-theme {
  --background-main: #333333;
  --background-alternate: #1a1a1a;

  --text-main: #ffffff;
  --text-highlight: #4da6ff;

  --button-primary-bg: #4da6ff;
  --button-primary-text: #0a0a0a;
  --button-secondary-bg: #888888;
  --button-secondary-text: #ffffff;
  --button-warning-bg: #ff6347;
  --button-warning-text: #ffffff;
}

