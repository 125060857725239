.cta {
  position: relative;
  /* margin: auto; */
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  cursor: pointer;
  text-shadow: 2px 12px 8px rgba(0, 0, 0, 0.6);
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: #e7AB42; 
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #ffffff;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  /* background: #e7AB42;  */
  background: #e7AB42;
}
/* F4C154 */
.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}
@media (max-width:600px){
  .cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: #e7AB42; 
    width: 65px;
    height: 65px;
    transition: all 0.3s ease;
  }
  
}