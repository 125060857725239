.frosted-nav {
  width: 100%;
  padding: px 0;
  /* background: rgba(255, 255, 255, 0.2); */
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  transition: top 0.3s;
  color: white;
}

.frosted-nav.visible {
  top: 0;
}

.frosted-nav.hidden {
  top: -160px;  /* Adjust based on your nav height */
}

.frosted-nav .logo {
  /* height: 60px; */
  /* margin-left: 10px; */
}

.frosted-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.frosted-nav li {
  padding: 10px 20px;
}

.frosted-nav a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
}
.frosted-nav li {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.frosted-nav a:hover {
  /* color: #555; */
  color: #e7AB42;
}
.frosted-nav li:hover {
  /* color: #555; */
  color: #e7AB42;
}

.nav-logo{
  height: 80px;
  padding-left: 50px;
}
@media (max-width: 600px){
  .nav-logo{
    padding-left: 0px;
    height: 50px;
  }
  .frosted-nav a {
    font-size: 8px;
  }
  .frosted-nav li {
    font-size: 8px;
  }
  .frosted-nav{
    padding-top: 10px;
    padding-bottom: 10px;
  }
}