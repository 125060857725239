.cta-section__dark{
  padding-top: 80px;
  padding-bottom: 80px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #114b7a;
  flex-wrap: wrap;
}
.cta-section-button-holder{
  display: flex;
  width: 100%;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: wrap;
  gap:15px
}
.flowtask-logo{
  height: 150px;
}

@media (max-width:600px){
  .cta-section-button-holder{
    justify-content: center;
  }
}