.footer__container{
  padding-top: 50px;
  padding-bottom: 10px;
  min-height: 200px;
  background-color: black;
  color: white;
  text-align: center;

}
.three-section-split{
  display: flex;
  justify-content: space-around;
  gap: 20px;
  align-items: center;
}
.footer__logo{
  width: 100px;
}
.split{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.split a{
  color: white;
}
.split h4{
  text-decoration: underline;
  cursor: pointer;

}