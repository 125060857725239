.jobs-section {
  padding: 2%;
}

.jobs-list .job-card {
  cursor: pointer;
  width: 300px; /* Adjust width as necessary */
  min-height: 100px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures that the title and more info are spaced out */
  background: #fff; /* Optional: Sets a white background */
}


.jobs-list .job-card h3 {
  margin: 0; /* Removes default margin */
  color: #333; /* Dark grey color for the text */
}

.jobs-list .job-card .more-info {
  color: #db5b20; /* Sets a default blue color for the link */
  text-decoration: none; /* Removes underline */
  transition: color 0.3s ease-in-out;
  font-weight: bold;
}

.jobs-list .job-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px); /* Gives a slight lift to the card */
  color: #db5b20; /* Darker blue on hover */
}
.jobs-list .job-card .more-info:hover {
  text-decoration: underline; /* Adds underline on hover */
}
