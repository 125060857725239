.MainHeadlineSubOpacity__container {
  position: relative;
  height: 75vh;
  /* background-image: url("../../../images/logos/Flortask_Logo\ copy.jpeg"); */
  background: linear-gradient(135deg, #f2faef 0%, #114d81 50%, #114b7a 100%);
  
  /* background-size: cover;  */
  color: white;
  text-shadow: 2px 12px 8px rgba(0, 0, 0, 0.6);
  animation: shadowAppear 3s ease-in-out forwards; /* Duration, timing function, and fill mode */
}
@keyframes shadowAppear {
  from {
      text-shadow: none;
  }
  to {
      text-shadow: 2px 12px 8px rgba(0, 0, 0, 0.6);
  }
}
.main-headline-logo{
  height: 250px;
  animation: spinAndGrow 1s ease-in-out forwards;
}

@keyframes spinAndGrow {
  0% {
    transform: rotate(0deg) scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
}


.MainHeadlineSubOpacity__container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the color as needed */
  /* backdrop-filter: blur(1px); */
  z-index: 1; 
  /* Overlay is above the image but below the content */
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.content h1{
  font-size: 4ch;
}
.MainHeadlineSubOpacity__container .content {
  position: relative;
  z-index: 2; /* Ensures text is above the overlay */
  padding: 20px; /* Optional for text positioning */
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
}

@media (max-width:985px){
  .content h1{
    font-size: 30px;
  }
  .content h2{
    font-size: 20px;
  }
}
@media (max-width:600px){
  .content h1{
    font-size: 25px;
  }
  .content h2{
    font-size: 15px;
  }
  .MainHeadlineSubOpacity__container{
  background-position: 50% 50%;
  }
  .main-headline-logo{
    height: 150px;
    animation: spinAndGrow 1s ease-in-out forwards;
  }
}