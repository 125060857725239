.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1.5s;
  text-align: center;
  z-index: 999;
}

.modal-content {
  background: white;
  padding: 40px;
  border-radius: 8px;
  position: relative;
  animation: slideIn 1s;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
}

/* Animation Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
    
  }
  to {
    opacity: 1;
  
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
    
  }
  to {
    transform: translateY(0);
    opacity: 1;
    
  }
}
