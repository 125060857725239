.TextAndImageRightToLeft__container{
  display: flex;
  /* padding-top: 60px;
  padding-bottom: 60px; */
  padding: 5%;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  background-color: #114b7a;
  /* background: linear-gradient(135deg, #f2faef 0%, #114d81 50%, #114b7a 100%); */
  color: white;
  }
  
  .TextAndImageRightToLeft__right{
    width: 48%;
  }
  .TextAndImageRightToLeft__left{
    width: 48%;
  }
  .TextAndImageRightToLeft__img{
    width: 80%;
    border-radius: 5%;
  }

  @media (max-width:800px){
    .TextAndImageRightToLeft__container{
      display: flex;
      padding-top: 60px;
      padding-bottom: 60px;
      /* padding: 2%; */
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
      background-color: #114b7a;
      /* background: linear-gradient(135deg, #f2faef 0%, #114d81 50%, #114b7a 100%); */
      color: white;
      }
      .TextAndImageRightToLeft__right{
        width: 80%;
      }
      .TextAndImageRightToLeft__left{
        width: 80%;
      }
      .TextAndImageRightToLeft__img{
        width: 100%;
        border-radius: 5%;
      }
  }