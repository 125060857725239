.why-section__container{
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: black;
}
.why-section__title{
  text-align: center;
  color: white;
  font-size: larger;
}

.three-item-flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 15px;
}
.item-flex{
width: 25%;
height: 100%;
min-height: 300px;
background-color: rgb(255, 255, 255);
padding: 2%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
/* background-image: URL("../../images/backgrounds/home.jpg"); */
}
.cta-section__dark{
  padding-top: 80px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: brown;
}
.cta-section-button-holder{
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: center;
}