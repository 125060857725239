.TextAndImageLeftToRight__container{
display: flex;
/* padding-top: 40px;
padding-bottom: 40px; */
padding: 5%;
justify-content: center;
align-items: center;
}

.TextAndImageLeftToRight__right{
  width: 48%;
}
.TextAndImageLeftToRight__left{
  width: 48%; padding: 2%;
}
.TextAndImageLeftToRight__img{
  width: 80%;
  border-radius: 5%;
}

@media (max-width:800px){
  .TextAndImageLeftToRight__container{
    display: flex;
    padding-top: 60px;
    padding-bottom: 60px;
    /* padding: 2%; */
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    }
    .TextAndImageLeftToRight__right{
      width: 80%;
    }
    .TextAndImageLeftToRight__left{
      width: 80%;
    }
    .TextAndImageLeftToRight__img{
      width: 100%;
      border-radius: 5%;
    }
}