/* Base theme (could be seen as your default accountant theme) */
:root {
  /* Backgrounds */
  --background-main: #5193d5;
  --background-alternate: #ffffff;

  /* Text colors */
  --text-main: #212529;
  --text-highlight: #0d6efd;

  /* Button colors */
  --button-primary-bg: #0d6efd;
  --button-primary-text: #ffffff;
  --button-secondary-bg: #6c757d;
  --button-secondary-text: #ffffff;
  --button-warning-bg: #dc3545;
  --button-warning-text: #ffffff;

  /* Button styling */
  --button-padding: 10px 20px;
  --button-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

  /* Container Sizes */
  --container-hero-height: 50vh;
  --container-hero-min-height: 300px;
  --container-hero-width: 100%;
}

/* Accountant Theme specific adjustments */
.accountant-theme {
  --background-main: #000000;
  --background-alternate: #a42929;

  --text-main: #eeeeee;
  --text-highlight: #ffcccc;

  --button-primary-bg: #a42929;
  --button-primary-text: #ffffff;
  --button-secondary-bg: #292929;
  --button-secondary-text: #eeeeee;
  --button-warning-bg: #ff4545;
  --button-warning-text: #000000;
}

/* Dark theme overrides */
.dark-theme {
  --background-main: #333333;
  --background-alternate: #1a1a1a;

  --text-main: #ffffff;
  --text-highlight: #4da6ff;

  --button-primary-bg: #4da6ff;
  --button-primary-text: #0a0a0a;
  --button-secondary-bg: #888888;
  --button-secondary-text: #ffffff;
  --button-warning-bg: #ff6347;
  --button-warning-text: #ffffff;
}