.hero-section__container {
  position: relative;
  height: 50vh;
  /* background-image: url("../../../images/backgrounds/home.jpg"); */
  background-size: cover; /* Ensures the image covers the entire container */
  color: white; /* Sets text color to white */
  text-shadow: 2px 12px 8px rgba(0, 0, 0, 0.6);
  animation: shadowAppear 3s ease-in-out forwards; /* Duration, timing function, and fill mode */
  display: flex;
  flex-direction: column;
  align-items: center;
}
@keyframes shadowAppear {
  from {
      text-shadow: none;
  }
  to {
      text-shadow: 2px 12px 8px rgba(0, 0, 0, 0.2);
  }
}

.hero-section__container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the color as needed */
  /* backdrop-filter: blur(1px); */
  z-index: 1; /* Overlay is above the image but below the content */
}
.hero-section__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70%;
}
.hero-section__content h1{
  font-size: 4ch;
}
.hero-section__container .hero-section__content {
  position: relative;
  z-index: 2; /* Ensures text is above the overlay */
  padding: 20px; /* Optional for text positioning */
}

@media (max-width:985px){
  
  .hero-section__content h1{
    font-size: 30px;
  }
  .hero-section__content h2{
    font-size: 20px;
  }
}
@media (max-width:600px){
  .hero-section__container{
    padding-top: 60px;
  }
  .hero-section__content h1{
    font-size: 25px;
  }
  .hero-section__content h2{
    font-size: 15px;
  }
  .hero-section__container{
  background-position: 50% 50%;
  }
}